








import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { UserStoreModule } from '@common-src/store/modules/user';
import { ModuleType } from '@common-src/enum/module-type.enum';

@Component
export default class PermissionDenyComponent extends BaseComponent {
    loginClick() {
        UserStoreModule.SET_USERINFO();
        if (JTL.moduleType === ModuleType.WORKORDER) {
            window.open(location.origin + '/#/sign/login', '_self');
        } else {
            this.$router.push('/sign/login');
        }
    }
}
